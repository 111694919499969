import React from "react";
import Header from "../../../../components/Headers/HeaderForLoggedIn";
// import NavigationBar from "../../../components/NavigationBarForContent";
import Footer from "../../../../components/Footers/FooterForLoggedIn";

const PrivacyPolicy = () => {
  return (
    <div>
      <Header />
      {/* <NavigationBar /> */}
      <div className="">
        <main>
          <div className="min-h-screen bg-gray-50 flex flex-col justify-start ">
            {/* sm:px-6 lg:px-8 */}
            <div className="mt-10">
              <h2 className="text-center text-3xl text-gray-900">
                Privacy Policy
              </h2>
              <div className="flex flex-col items-center py-8">
                <div className="flex flex-col w-full mb-12 text-left">
                  <div className="w-[90%] mx-auto px-3 px-lg-0 text-justify">
                    <p className="mb-0 mx-auto text-base font-medium leading-relaxed text-gray-600">
                      Hajj Umrah Inc. (“Hajj Umrah” “we” or “us”) is committed
                      to protecting your privacy. We have prepared this Privacy
                      Policy to describe to you our practices regarding the
                      Personal Information (as defined below) we collect, why we
                      collect it, and how we use and disclose it.
                      <br />
                      <br />
                      Your privacy matters to us, so please do take the time to
                      get to know and familiarize yourself with our policies and
                      practices. Please understand that we reserve the right to
                      change any of our policies and practices at any time, but
                      you can always find the latest version of this Privacy
                      Policy here on this page.
                      <br />
                      <br />
                    </p>

                    <h2 className="mx-auto mt-4 mb-4 text-2xl text-black">
                      Personal Information We Collect
                    </h2>

                    <p className="mb-0 mx-auto text-base font-medium leading-relaxed text-gray-600">
                      As used herein, “Personal Information” means information
                      that identifies or is reasonably capable of identifying an
                      individual, directly or indirectly, and information that
                      is capable of being associated with an identified or
                      reasonably identifiable individual.
                      <br />
                      <br />
                    </p>

                    <h3 className="mx-auto mt-4 mb-4 text-xl text-black">
                      Personal Information we collect from you
                    </h3>

                    <p className="mb-0 mx-auto text-base font-medium leading-relaxed text-gray-600">
                      We may collect the following categories of Personal
                      Information directly from you:
                      <br />
                      <br />
                    </p>
                    <ul className="list-disc ml-0 md:ml-12 text-gray-600">
                      <li>
                        <span className="text-black font-bold">
                          Identification Information
                        </span>
                        , such as name, email, phone number, postal address;
                      </li>
                      <li>
                        <span className="text-black font-bold">
                          Commercial Activity
                        </span>
                        , such as transactions, payments, order activity and
                        status;
                      </li>

                      <li>
                        <span className="text-black font-bold">
                          Correspondence
                        </span>
                        , such as information you provide to us in
                        correspondence, including account opening and customer
                        support; and
                      </li>

                      <li>
                        <span className="text-black font-bold">
                          Sensory Information
                        </span>
                        , such as images that you upload to your User Profile;
                      </li>
                    </ul>
                    <h3 className="mx-auto mt-4 mb-4 text-xl text-black">
                      Personal Information we collect automatically
                    </h3>

                    <p className="mb-0 mx-auto text-base font-medium leading-relaxed text-gray-600">
                      We may collect the following categories of Personal
                      Information automatically through your use of our
                      services:
                      <br />
                      <br />
                    </p>
                    <ul className="list-disc ml-0 md:ml-12 text-gray-600">
                      <li>
                        <span className="text-black font-bold">
                          Online Identifiers
                        </span>
                        , such as IP address, domain name;
                      </li>

                      <li>
                        <span className="text-black font-bold">
                          Device Information
                        </span>
                        , such as IMEI, hardware, operating system, browser;
                      </li>

                      <li>
                        <span className="text-black font-bold">Usage Data</span>
                        , such as system activity, internal and external
                        information related to Hajj Umrah pages you visit,
                        clickstream information; and
                      </li>

                      <li>
                        <span className="text-black font-bold">
                          Geolocation Data
                          <br />
                          <br />
                        </span>
                      </li>
                    </ul>

                    <p className="mb-0 mx-auto text-base font-medium leading-relaxed text-gray-600">
                      Our automatic collection of Personal Information may
                      involve the use of Cookies, described in greater detail
                      below.
                      <br />
                      <br />
                    </p>

                    <h3 className="mx-auto mt-4 mb-4 text-xl text-black">
                      Personal Information we collect from third parties
                    </h3>

                    <p className="mb-0 mx-auto text-base font-medium leading-relaxed text-gray-600">
                      We may collect and/or verify the following categories of
                      Personal Information about you from third parties:
                      <br />
                      <br />
                    </p>
                    <ul className="list-disc ml-0 md:ml-12 text-gray-600">
                      <li>
                        <span className="text-black font-bold">
                          Identification Information
                        </span>
                        , such as name, email, phone number, postal address
                      </li>

                      <li>
                        <span className="text-black font-bold">
                          Transaction Information
                        </span>
                        , such as public blockchain data and distributed ledger
                        technology (bitcoin, ether, and other Digital Assets)
                        are not truly anonymous. We, and any others who can
                        match your public Digital Asset address to other
                        Personal Information about you, may be able to identify
                        you from a blockchain transaction because, in some
                        circumstances, Personal Information published on a
                        blockchain (such as your Digital Asset address and IP
                        address) can be correlated with Personal Information
                        that we and others may have. Furthermore, by using data
                        analysis techniques on a given blockchain, it may be
                        possible to identify other Personal Information about
                        you);
                      </li>

                      <li>
                        <span className="text-black font-bold">
                          Financial Information
                        </span>
                        , such as bank account information, routing number,
                        credit card number, debit card number; and
                      </li>

                      <li>
                        <span className="text-black font-bold">
                          Additional Information
                        </span>
                        , at our discretion to comply with legal obligations.
                        <br />
                        <br />
                      </li>
                    </ul>
                    <h3 className="mx-auto mt-4 mb-4 text-xl text-black">
                      Accuracy and retention of Personal Information
                    </h3>

                    <p className="mb-0 mx-auto text-base font-medium leading-relaxed text-gray-600">
                      We take reasonable and practicable steps to ensure that
                      your Personal Information held by us is (i) accurate with
                      regard to the purposes for which it is to be used, and
                      (ii) not kept longer than is necessary for the fulfillment
                      of the purpose for which it is to be used.
                      <br />
                      <br />
                    </p>

                    <h3 className="mx-auto mt-4 mb-4 text-xl text-black">
                      How We Use Your Personal Information
                    </h3>

                    <p className="mb-0 mx-auto text-base font-medium leading-relaxed text-gray-600">
                      We collect Personal Information about you in an attempt to
                      provide you with the best experience possible, protect you
                      from risks related to improper use and fraud, and help us
                      maintain and improve our Services. We may use your
                      Personal Information to:
                      <br />
                      <br />
                    </p>

                    <ol className="ml-12 list-decimal text-gray-600">
                      <li>
                        <span className="text-black font-bold">
                          Provide you with our Services.{" "}
                        </span>
                        We use your Personal Information to provide you with our
                        Services pursuant to the terms of our Terms of Use. For
                        example, in order to facilitate fiat transfers out of
                        your account, we need to know your financial information
                      </li>
                      <li>
                        <span className="text-black font-bold">
                          Comply with legal and regulatory requirements.{" "}
                        </span>
                        We process your Personal Information as required by
                        applicable laws and regulations.
                      </li>
                      <li>
                        <span className="text-black font-bold">
                          Detect and prevent fraud.{" "}
                        </span>
                        We process your Personal Information to detect and
                        prevent fraud on your account, which is especially
                        important given the irreversible nature of
                        cryptocurrency transactions.
                      </li>
                      <li>
                        <span className="text-black font-bold">
                          Protect the security and integrity of our Services.{" "}
                        </span>
                        We use your Personal Information, including information
                        about your device and your activity on Hajj Umrah to
                        maintain the security of your account and the Hajj Umrah
                        platform.
                      </li>
                      <li>
                        <span className="text-black font-bold">
                          Provide you with customer support.{" "}
                        </span>
                        We process your Personal Information when you contact
                        our support team with questions about or issues with
                        your account.
                      </li>
                      <li>
                        <span className="text-black font-bold">
                          Market our products.{" "}
                        </span>
                        We may contact you with information about our Services.
                        We will only do so with your permission, which can be
                        revoked at any time.
                      </li>
                      <li>
                        <span className="text-black font-bold">
                          Other business purposes.{" "}
                        </span>
                        We may use your Personal Information for additional
                        purposes if that purpose is disclosed to you before we
                        collect the information or if we obtain your consent.
                      </li>
                      <br />
                      <br />
                    </ol>

                    <h3 className="mx-auto mt-4 mb-4 text-xl text-black">
                      California Privacy Rights
                    </h3>

                    <p className="mb-0 mx-auto text-base font-medium leading-relaxed text-gray-600">
                      To the extent provided for by law and subject to
                      applicable exceptions, California residents have the
                      following privacy rights in relation to the Personal
                      Information we collect:
                      <br />
                      <br />
                    </p>

                    <ul className="list-disc ml-0 md:ml-12 text-black">
                      <li>
                        <span className="text-gray-600 font-medium">
                          The right to know what Personal Information we have
                          collected and how we have used and disclosed that
                          Personal Information;
                        </span>
                      </li>
                      <li>
                        <span className="text-gray-600 font-medium">
                          The right to request deletion of your Personal
                          Information; and
                        </span>
                      </li>
                      <li>
                        <span className="text-gray-600 font-medium">
                          The right to be free from discrimination relating to
                          the exercise of any of your privacy rights.{" "}
                        </span>
                      </li>{" "}
                      <br />
                    </ul>
                    <p className="mb-0 mx-auto text-base font-medium leading-relaxed text-gray-600">
                      Exercising Your Rights: California residents can exercise
                      the above privacy rights by emailing us at:
                      hello@example.com
                      {/* idher mail dalni ha company ki */}
                      <br />
                      Verification: in order to protect your Personal
                      Information from unauthorized access or deletion, we may
                      require you to verify your login credentials before you
                      can submit a request to know or delete Personal
                      Information. If you do not have an account with us, or if
                      we suspect fraudulent or malicious activity, we may ask
                      you to provide additional Personal Information for
                      verification. If we cannot verify your identity, we will
                      not provide or delete your Personal Information.
                      Authorized Agents: you may submit a request to know or a
                      request to delete your Personal Information through an
                      authorized agent. If you do so, the agent must present
                      signed written permission to act on your behalf and you
                      may also be required to independently verify your identity
                      with us.
                      <br />
                      <br />
                    </p>

                    <h3 className="mx-auto mt-4 mb-4 text-xl text-black">
                      How We Share Your Personal Information
                    </h3>

                    <p className="mb-0 mx-auto text-base font-medium leading-relaxed text-gray-600">
                      We will not share your Personal Information with third
                      parties, except as described below:
                      <br />
                      <br />
                    </p>

                    <ol className="ml-12 list-decimal text-gray-600">
                      <li>
                        <span className="text-black font-bold">
                          Service Providers.{" "}
                        </span>
                        We may share your Personal Information with third-party
                        service providers for business or commercial purposes,
                        including fraud detection and prevention, security
                        threat detection, payment processing, customer support,
                        data analytics, Information Technology, advertising and
                        marketing, network infrastructure, storage, transaction
                        monitoring. We share your Personal Information with
                        these service providers only so that they can provide us
                        with the services, and we prohibit our service providers
                        from using or disclosing your Personal Information for
                        any other purpose.
                      </li>
                      <li>
                        <span className="text-black font-bold">
                          Payment Processors.{" "}
                        </span>
                        You authorize us to collect and share with our payments
                        provider your Personal Information including full name,
                        email address and financial information, and you are
                        responsible for the accuracy and completeness of that
                        data. Your Personal Information collected by the payment
                        processor will be subject to their Privacy Policy
                      </li>
                      <li>
                        <span className="text-black font-bold">
                          Law Enforcement.{" "}
                        </span>
                        We may be compelled to share your Personal Information
                        with law enforcement, government officials, and
                        regulators
                      </li>
                      <li>
                        <span className="text-black font-bold">
                          Corporate Transactions.{" "}
                        </span>
                        We may disclose Personal Information in the event of a
                        proposed or consummated merger, acquisition,
                        reorganization, asset sale, or similar corporate
                        transaction, or in the event of a bankruptcy or
                        dissolution.
                      </li>
                      <li>
                        <span className="text-black font-bold">
                          Professional Advisors.{" "}
                        </span>
                        We may share your Personal Information with our
                        professional advisors, including legal, accounting, or
                        other consulting services for purposes of audits or to
                        comply with our legal obligations.
                      </li>
                      <li>
                        <span className="text-black font-bold">Consent. </span>
                        We may share your Personal Information with your consent
                      </li>
                    </ol>
                    <p className="mb-0 mx-auto text-base font-medium leading-relaxed text-gray-600">
                      If we decide to modify the purpose for which your Personal
                      Information is collected and used, we will amend this
                      Privacy Policy.
                      <br />
                      <br />
                    </p>

                    <h3 className="mx-auto mt-4 mb-4 text-xl text-black">
                      Cookies
                    </h3>
                    <p className="mb-0 mx-auto text-base font-medium leading-relaxed text-gray-600">
                      When you access Hajj Umrah we may make use of the standard
                      practice of placing tiny data files called cookies, flash
                      cookies, pixel tags, or other tracking tools (herein,
                      “Cookies”) on your computer or other devices used to visit
                      Hajj Umrah. We use Cookies to help us recognize you as a
                      customer, collect information about your use of Hajj Umrah
                      to better customize our services and content for you, and
                      collect information about your computer or other access
                      devices to: (i) ensure that your account security has not
                      been compromised by detecting irregular, suspicious, or
                      potentially fraudulent account activities; (ii) assess and
                      improve our services and advertising campaigns.
                      <br />
                      <br />
                      You also can learn more about cookies by visiting
                      http://www.allaboutcookies.org, which includes additional
                      useful information on cookies and how to block cookies on
                      different types of browsers and mobile devices. Please
                      note that if you reject cookies, you will not be able to
                      use some or all of Hajj Umrah If you do not consent to the
                      placing of Cookies on your device, please do not visit,
                      access, or use Hajj Umrah.
                      <br />
                      <br />
                    </p>

                    <h3 className="mx-auto mt-4 mb-4 text-xl text-black">
                      Direct Marketing
                    </h3>
                    <p className="mb-0 mx-auto text-base font-medium leading-relaxed text-gray-600">
                      Subject to applicable laws and regulations, we may from
                      time to time send direct marketing materials promoting
                      services, products, facilities, or activities to you using
                      information collected from or about you. You may also
                      opt-out of such communications by following the directions
                      provided in any marketing communication. It is our policy
                      to not provide your Personal Information for those third
                      parties’ direct marketing purposes without your consent.
                      <br />
                      <br />
                    </p>

                    <h3 className="mx-auto mt-4 mb-4 text-xl text-black">
                      Information Security
                    </h3>
                    <p className="mb-0 mx-auto text-base font-medium leading-relaxed text-gray-600">
                      No security is foolproof, and the Internet is an insecure
                      medium. We cannot guarantee absolute security, but we work
                      hard to protect Hajj Umrah and you from unauthorized
                      access to or unauthorized alteration, disclosure, or
                      destruction of Personal Information we collect and store.
                      Measures we take include encryption of the Hajj Umrah
                      website communications with SSL; optional two-factor
                      authentication; periodic review of our Personal
                      Information collection, storage, and processing practices;
                      and restricted access to your Personal Information on a
                      need-to-know basis for our employees, contractors and
                      agents who are subject to strict contractual
                      confidentiality obligations and may be disciplined or
                      terminated if they fail to meet these obligations.
                      <br />
                      <br />
                    </p>

                    <h3 className="mx-auto mt-4 mb-4 text-xl text-black">
                      Information For Persons Subject to EU Data Protection Law
                    </h3>
                    <p className="mb-0 mx-auto text-base font-medium leading-relaxed text-gray-600">
                      While customers who are located in the European Union
                      (“EU”), European Economic Area (“EEA”) or the Channel
                      Islands, or other locations subject to EU data protection
                      law (collectively, “Europe”) are customers of our US
                      entity, we recognize and, to the extent applicable to us,
                      adhere to relevant EU data protection laws. For purposes
                      of this section, “personal data” has the meaning provided
                      in the General Data Protection Regulation (EU) 2016/679
                      (“GDPR”).
                      <br />
                      <br />
                    </p>

                    <h3 className="mx-auto mt-4 mb-4 text-xl text-black">
                      Lawful bases for processing
                    </h3>
                    <p className="mb-0 mx-auto text-base font-medium leading-relaxed text-gray-600">
                      We process personal data subject to GDPR on one or more of
                      the following legal bases:
                      <br />
                      <br />
                    </p>
                    <ol className="ml-12 list-disc text-gray-600">
                      <li>
                        <span className="text-black font-bold">
                          Legal Obligation:{" "}
                        </span>
                        to conduct anti-fraud and to fulfill our retention and
                        other legal obligations;
                      </li>

                      <li>
                        <span className="text-black font-bold">
                          Contractual Obligation:{" "}
                        </span>
                        to satisfy our obligations to you under our Terms of
                        Use, including to provide you with our Services and
                        customer support services, and to optimize and enhance
                        Hajj Umrah;
                      </li>
                      <li>
                        <span className="text-black font-bold">
                          Legitimate Interest:{" "}
                        </span>
                        to monitor the usage of Hajj Umrah, conduct automated
                        and manual security checks of our Services, to protect
                        our rights; and
                      </li>
                      <li>
                        <span className="text-black font-bold">Consent: </span>
                        to market Hajj Umrah and our Services. You may withdraw
                        your consent at any time without affecting the
                        lawfulness of processing based on consent before consent
                        is withdrawn
                      </li>
                    </ol>

                    <h3 className="mx-auto mt-4 mb-4 text-xl text-black">
                      European privacy rights
                    </h3>
                    <p className="mb-0 mx-auto text-base font-medium leading-relaxed text-gray-600">
                      European residents have the following rights under GDPR,
                      subject to certain exceptions provided under the law, with
                      respect to their personal data:
                      <br />
                      <br />
                    </p>
                    <ol className="ml-12 list-disc text-gray-600">
                      <li>
                        <span className="text-black font-bold">
                          Rights to Access and Rectification.{" "}
                        </span>
                        You may submit a request that Hajj Umrah disclose the
                        personal data that we process about you and correct any
                        inaccurate personal data
                      </li>
                      <li>
                        <span className="text-black font-bold">
                          Right to Erasure.{" "}
                        </span>
                        You may submit a request that Hajj Umrah delete the
                        personal data that we have about you.
                      </li>
                      <li>
                        <span className="text-black font-bold">
                          Right to Restriction of Processing.{" "}
                        </span>
                        You have the right to restrict or object to our
                        processing of your personal data under certain
                        circumstances.
                      </li>
                      <li>
                        <span className="text-black font-bold">
                          Right to Data Portability.{" "}
                        </span>
                        You have the right to receive the personal data you have
                        provided to us in an electronic format and to transmit
                        that personal data to another data controller.
                      </li>
                    </ol>
                    <p className="mb-0 mx-auto text-base font-medium leading-relaxed text-gray-600">
                      To submit a request to exercise these rights, please
                      contact us using the methods described at the end of this
                      Privacy Policy. When handling requests to exercise
                      European privacy rights, we check the identity of the
                      requesting party to ensure that he or she is the person
                      legally entitled to make such request. While we maintain a
                      policy to respond to these requests free of charge, should
                      your request be repetitive or unduly onerous, we reserve
                      the right to charge you a reasonable fee for compliance
                      with your request.
                      <br />
                      <br />
                    </p>

                    <h3 className="mx-auto mt-4 mb-4 text-xl text-black">
                      Collection and transfer of data outside the EEA
                    </h3>
                    <p className="mb-0 mx-auto text-base font-medium leading-relaxed text-gray-600">
                      Hajj Umrah operates with many of our systems based in the
                      United States. As a result, we may transfer personal data
                      from Europe to third countries outside of Europe,
                      including the United States, under the following
                      conditions:
                      <br />
                      <br />
                    </p>
                    <ol className="ml-12 list-disc text-gray-600">
                      <li>
                        <span className="text-black font-bold">
                          Contractual Obligation.{" "}
                        </span>
                        Where transfers are necessary to satisfy our obligation
                        to you under our Terms of Use, including to provide you
                        with our Services and customer support services, and to
                        optimize and enhance Hajj Umrah; and
                      </li>
                      <li>
                        <span className="text-black font-bold">Consent: </span>
                        where you have consented to the transfer of your
                        personal data to a third country.
                      </li>
                    </ol>
                    <p className="mb-0 mx-auto text-base font-medium leading-relaxed text-gray-600">
                      Where transfers to a third country are based on your
                      consent, you may withdraw your consent at any time. Please
                      understand, however, that our services may not be
                      available if we are unable to transfer personal data to
                      third countries.
                      <br />
                      <br />
                      When we transfer personal data to third countries, we
                      endeavor to ensure adequate safeguards are implemented,
                      for example through the use of standard contractual
                      clauses or Privacy Shield certification.
                      <br />
                      <br />
                    </p>

                    <h3 className="mx-auto mt-4 mb-4 text-xl text-black">
                      Automated decision-making
                    </h3>
                    <p className="mb-0 mx-auto text-base font-medium leading-relaxed text-gray-600">
                      We may engage in automated decision-making for purposes of
                      fraud detection and prevention.
                      <br />
                      <br />
                    </p>

                    <h3 className="mx-auto mt-4 mb-4 text-xl text-black">
                      Contact Us
                    </h3>
                    <p className="mb-0 mx-auto text-base font-medium leading-relaxed text-gray-600">
                      If you have questions or concerns regarding this policy or
                      our processing of your Personal Hajj Umrah, LLC., Attn:
                      Privacy, 3801 Meadowknolls rd, Marion, IA, 52302, or by
                      email at hello@example.com
                      <br />
                      <br />
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
        <Footer />
      </div>
    </div>
  );
};

export default PrivacyPolicy;
