import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import bgimg from "../../assets/bgImage.png";
import HeaderForSignup from "../../components/Headers/HeaderForLogin";
import FooterForSignup from "../../components/Footers/FooterForSingup";
import FormInput from "../../components/FormInput";
import { loginUser } from "../../utility/AuthApis";
import Loader from "../../components/loader";
import { BiErrorAlt } from "react-icons/bi";

const LoginPage = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const [errors, setErrors] = useState({
    email: "",
    password: "",
  });
  const [apiError, setApiError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
    setErrors({ ...errors, [name]: "" });
    setApiError("");
  };
  const firebaseToken = localStorage.getItem('firebase_Token')

  const handleSubmit = async (event) => {
    event.preventDefault();
    setErrors({ email: "", password: "" }); // Clear errors on submit
    setApiError("");
    setIsLoading(true);

    if (!formData.email || !formData.password) {
      setErrors({
        email: formData.email ? "" : "Email is required",
        password: formData.password ? "" : "Password is required",
      });
      setIsLoading(false);
      return;
    }

    try {
      const response = await loginUser(formData.email, formData.password, firebaseToken);
      localStorage.setItem("SignedUp-User-Profile", JSON.stringify(response));
      navigate("/dashboard");
    } catch (error) {
      console.error("Login error:", error);
      setApiError("Invalid email or password");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div
      className="flex flex-col min-h-screen font-sans"
      style={{
        backgroundImage: `url(${bgimg})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
      }}
    >
      <HeaderForSignup />

      <main className="flex-grow flex justify-center items-center p-4">
        <div className="w-full max-w-md py-12 px-6 bg-white rounded-lg shadow-custom-shadow md:mx-auto">
          <h2 className="text-base font-medium mb-1 text-gray-600 text-center">
            Log in to your account
          </h2>

          <FormInput
            label="Email address"
            type="email"
            name="email"
            value={formData.email}
            onChange={handleInputChange}
            placeholder="Enter your email address"
            error={errors.email}
          />

          <FormInput
            label="Password"
            type="password"
            name="password"
            value={formData.password}
            onChange={handleInputChange}
            placeholder="********"
            error={errors.password}
          />

          {apiError && (
            <div
              className="text-red-500 text-xs flex items-center
                            gap-1 mt-1 mb-4"
            >
              <BiErrorAlt />{" "}
              <div className=" text-red-600 text-sm">{apiError}</div>
            </div>
          )}

          <button
            onClick={handleSubmit}
            className={`w-full text-sm p-2 mb-3 bg-[#00936C] text-white rounded-md hover:bg-green-900 flex items-center justify-center ${
              isLoading ? "opacity-50 cursor-not-allowed" : ""
            }`}
            disabled={isLoading}
          >
            Continue
            {isLoading && <Loader />}
          </button>

          <div className="text-center text-xs font-thin text-gray-500 my-4">
            OR
          </div>

          {/* <button
            className="w-full text-sm flex items-center justify-center p-2 mb-3 border text-gray-500 rounded-md cursor-not-allowed"
            disabled
          >
            <img
              src="https://upload.wikimedia.org/wikipedia/commons/thumb/c/c1/Google_%22G%22_logo.svg/768px-Google_%22G%22_logo.svg.png"
              alt="Google"
              className="w-4 mr-2 ml-2"
            />
            Sign up with Google
          </button>
          <button
            className="w-full text-sm flex items-center justify-center p-2 mb-3 border text-gray-500 rounded-md cursor-not-allowed"
            disabled
          >
            <img
              src="https://upload.wikimedia.org/wikipedia/commons/thumb/f/fa/Apple_logo_black.svg/512px-Apple_logo_black.svg.png"
              alt="Apple"
              className="w-4 mr-2"
            />
            Sign up with Apple
          </button> */}
          <div className="text-sm text-[#00936C] hover:text-green-800 cursor-pointer mt-2 text-center">
            Forgot Password?
          </div>
        </div>
      </main>

      <FooterForSignup />
    </div>
  );
};

export default LoginPage;
