import React, { useState } from 'react';
import { updateHotelCheckIn } from '../../../../../utility/Api'; // Import the API function

const HotelCheckIn = ({ booking }) => {
  const [isCheckInMakkah, setIsCheckInMakkah] = useState(booking.is_check_in_makkah || false);
  const [isCheckInMadinah, setIsCheckInMadinah] = useState(booking.is_check_in_madinah || false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleCheckInSubmit = async (hotel) => {
    setLoading(true);
    setError(null);

    const checkInData = {
      booking_number: booking.booking_number,
      session_token: booking.user_session_token,
      partner_session_token: booking.partner_session_token,
      is_check_in_makkah: hotel === 'Makkah' ? isCheckInMakkah : booking.is_check_in_makkah,
      is_check_in_madinah: hotel === 'Madinah' ? isCheckInMadinah : booking.is_check_in_madinah,
    };

    try {
      await updateHotelCheckIn(checkInData);
      alert('Check-in status updated successfully.');
    } catch (err) {
      setError('Error updating check-in status.');
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="hotel-check-in">
      {/* Makkah Hotel Check-In */}
      <div className="check-in-section mb-4 p-4 border rounded-lg shadow-md">
        <h3 className="text-lg font-medium mb-2">Makkah Hotel Check-In</h3>
        <div className="flex items-center space-x-4 mb-4">
          <label className="custom-radio flex items-center">
            <input
              type="radio"
              name="makkahCheckIn"
              value="yes"
              checked={isCheckInMakkah}
              onChange={() => setIsCheckInMakkah(true)}
              className="hidden"
            />
            <span className="radio-button"></span>
            Yes
          </label>
          <label className="custom-radio flex items-center">
            <input
              type="radio"
              name="makkahCheckIn"
              value="no"
              checked={!isCheckInMakkah}
              onChange={() => setIsCheckInMakkah(false)}
              className="hidden"
            />
            <span className="radio-button"></span>
            No
          </label>
        </div>
        <button
          onClick={() => handleCheckInSubmit('Makkah')}
          disabled={loading}
          className="px-4 py-2 bg-[#00936C] text-white rounded-md hover:bg-green-800 disabled:bg-gray-400"
        >
          {loading ? 'Submitting...' : 'Submit'}
        </button>
        {error && <p className="text-red-500 mt-2">{error}</p>}
      </div>

      {/* Madinah Hotel Check-In */}
      <div className="check-in-section p-4 border rounded-lg shadow-md">
        <h3 className="text-lg font-medium mb-2">Madinah Hotel Check-In</h3>
        <div className="flex items-center space-x-4 mb-4">
          <label className="custom-radio flex items-center">
            <input
              type="radio"
              name="madinahCheckIn"
              value="yes"
              checked={isCheckInMadinah}
              onChange={() => setIsCheckInMadinah(true)}
              className="hidden"
            />
            <span className="radio-button"></span>
            Yes
          </label>
          <label className="custom-radio flex items-center">
            <input
              type="radio"
              name="madinahCheckIn"
              value="no"
              checked={!isCheckInMadinah}
              onChange={() => setIsCheckInMadinah(false)}
              className="hidden"
            />
            <span className="radio-button"></span>
            No
          </label>
        </div>
        <button
          onClick={() => handleCheckInSubmit('Madinah')}
          disabled={loading}
          className="px-4 py-2 bg-[#00936C] text-white rounded-md hover:bg-green-800 disabled:bg-gray-400"
        >
          {loading ? 'Submitting...' : 'Submit'}
        </button>
        {error && <p className="text-red-500 mt-2">{error}</p>}
      </div>
    </div>
  );
};

export default HotelCheckIn;