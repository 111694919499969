import React, { createContext, useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  useLocation,
} from "react-router-dom";
import { requestForToken, onMessageListener } from "./firebaseConfig";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import "./customToast.css"; // Import the custom CSS for toast
import notificationlogo from "../src/assets/noticationlogo.svg";

import LoginPage from "./pages/login/login.tsx";
// import SignupPage from "./pages/Signup/SignupPage";
import EmailSignupPage from "./pages/Signup/EmailSignupPage";
import PasswordSignupPage from "./pages/PasswordSignupPage/PasswordSignupPage";
import OTPVerificationPage from "./pages/OTP/otpverification";
import ServiceSelectionPage from "./pages/AccountSetup/firstForm";
import CompanyFormPage from "./pages/AccountSetup/CompanyForm/CompanyForms";
import Individual from "./pages/AccountSetup/IndividualForm/Individual";
import NotificationPage from "./pages/AccountReview/AccountReview";
import Dashboard from "./pages/Admin-Panel/Dashboard/Dashboard";
import PackageType from "./pages/Admin-Panel/PackageManagement/PackageType/PackageType";
import CreatePackagePage from "./pages/Admin-Panel/PackageManagement/CreateCompanyPackageForms/CreatePackagePage";
import PackageDetails from "./pages/Admin-Panel/PackageManagement/PackageDetailedPage/PackageDetailedPage.js";
import EditPackagePage from "./pages/Admin-Panel/PackageManagement/EditCompanyPackageForms/EditPackagePage";
import ContinueCreatedCompanyForms from "./pages/Admin-Panel/PackageManagement/ContinueCreatedCompanyForms/ContinueCreatedCompanyForms";
import PackageManagement from "./pages/Admin-Panel/PackageManagement/PackagesList/PackageManagement";
import IndTransportForm from "./pages/Admin-Panel/PackageManagement/CreateIndividualPackageForm/TransportForm";
import Profile from "./pages/Admin-Panel/ProfilePage/Profile";
import Bookings from "./pages/Admin-Panel/Bookings/Bookings.jsx";
import BookingDetails from "./pages/Admin-Panel/Bookings/BookingDetailsPage/BookingDetails";
import Wallet from "./pages/Admin-Panel/Wallet/wallet.jsx";
import AllPayments from "./pages/Admin-Panel/Wallet/components/ReceivablePayments/AllPayments.jsx"; // Adjust import according to your file structure
import Reviews from "./pages/Admin-Panel/ReviewsRatings/Reviews.jsx";

import AirlineTickets from "./pages/Admin-Panel/Bookings/BookingDetailsPage/ActiveSatusComponents/AirlineTickets/AirlineTickets.jsx";
import TransportArrangement from "./pages/Admin-Panel/Bookings/BookingDetailsPage/ActiveSatusComponents/TransportArrangement/TransportArrangement.jsx";
import HotelArrangement from "./pages/Admin-Panel/Bookings/BookingDetailsPage/ActiveSatusComponents/HotelArrangement/HotelArrangement.jsx";
import UploadEvisa from "./pages/Admin-Panel/Bookings/BookingDetailsPage/ActiveSatusComponents/UploadVisa/UploadEvisa.jsx";

import WithdrawHistory from "./pages/Admin-Panel/Wallet/components/WithdrawHistory.js";
import AccountStatementHistory from "./pages/Admin-Panel/Wallet/components/AccountStatementHistory.js";
import Complaints from "./pages/Admin-Panel/Complaints/Complaints.js";

import FQA from "./pages/Admin-Panel/ExtraPages/FrequentlyAskedQuestions/FQA";
import PrivacyPolicy from "./pages/Admin-Panel/ExtraPages/PrivacyPolicy/PrivacyPolicy.jsx";
import TermsServices from "./pages/Admin-Panel/ExtraPages/TermsServices/TermsServices.jsx";
import Documentation from './pages/Admin-Panel/ExtraPages/Documentation-Page/doc'

import ScrollToTopButton from "./components/ScrollToTopButton"; // Import the ScrollToTopButton component
import "./components/ScrollToTopButton.css"; // Import the CSS for ScrollToTopButton

import { UserProvider } from "./context/UserContext";
import { BookingProvider } from "./context/BookingContext";

import useUserValidation from "./utility/useUserValidation";
// Utility function to get user status from local storage
const getUserStatus = () => {
  // localStorage.clear();

  const profile = localStorage.getItem("SignedUp-User-Profile");

  if (!profile) return { isLoggedIn: false };
  try {
    const { is_email_verified, partner_type, account_status, partner_type_and_detail } =
      JSON.parse(profile);

    return {
      isLoggedIn: true,
      isEmailVerified: is_email_verified,
      partnerType: partner_type,
      accountStatus: account_status,
      typeAndDetail: partner_type_and_detail,
    };
  } catch (e) {
    console.error("Error parsing profile JSON:", e);
    return { isLoggedIn: false };
  }
};

const ProtectedRoute = ({ element, ...rest }) => {
  const {
    isLoggedIn,
    isEmailVerified,
    partnerType,
    accountStatus,
    typeAndDetail,
  } = getUserStatus();
  const location = useLocation();
  const path = location.pathname;

  useUserValidation(); // Call the custom hook

  const alwaysAllowedPaths = [
    "/faq",
    "/privacy-policy",
    "/terms-of-services",
    "/documentation"
  ];

  if (alwaysAllowedPaths.includes(path)) {
    return element;
  }

  if (!isLoggedIn) {
    if (
      !["/sign-in",
        // "/signup",
        "/signup/password", "/"].includes(path)
    ) {
      return <Navigate to="/sign-in" replace />;
    }
  }

  if (isLoggedIn && !isEmailVerified) {
    return path !== "/otp-verification" ? (
      <Navigate to="/otp-verification" replace />
    ) : (
      element
    );
  }

  if (isLoggedIn && isEmailVerified && partnerType === "NA") {
    if (["/individual-registration", "/company-registration"].includes(path)) {
      return <Navigate to="/service-selection" replace />;
    }
    return ![
      "/service-selection",
      "/individual-registration",
      "/company-registration",
    ].includes(path) ? (
      <Navigate to="/service-selection" replace />
    ) : (
      element
    );
  }

  if (isLoggedIn && isEmailVerified && partnerType === null) {
    return path !== "/service-selection" ? (
      <Navigate to="/service-selection" replace />
    ) : (
      element
    );
  }

  if (
    isLoggedIn &&
    isEmailVerified &&
    partnerType !== "NA" &&
    accountStatus === "Pending"
  ) {
    if (typeAndDetail === null) {
      if (partnerType === "Individual" && path !== "/individual-registration") {
        return <Navigate to="/individual-registration" replace />;
      }
      if (partnerType === "Company" && path !== "/company-registration") {
        return <Navigate to="/company-registration" replace />;
      }
    } else {
      if (path !== "/review") {
        return <Navigate to="/review" replace />;
      }
    }
    return element;
  }

  if (
    isLoggedIn &&
    isEmailVerified &&
    partnerType !== "NA" &&
    accountStatus === "Active"
  ) {
    const allowedPaths = [
      "/dashboard",
      "/package-type",
      "/company/package-creation",
      "/edit-package",
      "/company/continue-existing-package-creation",
      "/packagedetails",
      "/packages",
      "/individual/package-creation",
      "/profile",
      "/booking",
      "/bookingdetails",
      "/wallet",
      "/package/upload-evisa",
      "/package/airline-tickets",
      "/package/transport-arrangement",
      "/package/hotel-arrangement",
      "/reviews-ratings",
      "/withdrawhistory",
      "/accountstatementhistory",
      "/faq",
      "/privacy-policy",
      "/terms-of-services",
      "/complaints",
      "/all-payments",
    ];
    if (!allowedPaths.includes(path)) {
      return <Navigate to="/dashboard" replace />; // or any other default allowed path
    }
    return element;
  }

  return element;
};

// Create a context for navigation state
export const NavigationContext = createContext();

const App = () => {
  const [isEmailSignupVisited, setIsEmailSignupVisited] = useState(false);

  // firebase code start
  const [notification, setNotification] = useState({ title: "", body: "" });
  const [token, setToken] = useState("");

  useEffect(() => {
    requestForToken().then(() => {
      const userToken = localStorage.getItem("firebase_Token");
      if (userToken) {
        setToken(userToken);
      }
    });

    onMessageListener()
      .then((payload) => {
        setNotification({
          title: payload.notification.title,
          body: payload.notification.body,
        });
        showToast(payload.notification.title, payload.notification.body);
      })
      .catch((err) => console.log("failed: ", err));
  }, []);

  // Register service worker
  if ("serviceWorker" in navigator) {
    window.addEventListener("load", () => {
      navigator.serviceWorker
        .register("/firebase-messaging-sw.js")
        .then((registration) => {
          console.log(
            "ServiceWorker registration successful with scope: ",
            registration.scope
          );
        })
        .catch((error) => {
          console.log("ServiceWorker registration failed: ", error);
        });
    });
  }

  const showToast = (title, body) => {
    toast(
      <div className="custom-toast">
        <img src={notificationlogo} alt="Company Logo" className="toast-logo" />
        <div className="toast-content">
          <h4 className="mb-2">{title}</h4>
          <p>{body}</p>
        </div>
      </div>,
      {
        autoClose: false, // Disable auto close
        closeOnClick: true, // Allow close on click
        icon: false, // Disable default icon
      }
    );
  };

  // firebase code end
  return (
    <UserProvider>
      <BookingProvider>
        <NavigationContext.Provider
          value={{ isEmailSignupVisited, setIsEmailSignupVisited }}
        >
          <Router>
            <Routes>
              {/* <Route
                path="/signup"
                element={<ProtectedRoute element={<SignupPage />} />}
              /> */}
              <Route
                path="/sign-in"
                element={<ProtectedRoute element={<LoginPage />} />}
              />
              {/* /signup/email */}
              <Route
                path="/"
                element={<ProtectedRoute element={<EmailSignupPage />} />}
              />
              <Route
                path="/signup/password"
                element={<ProtectedRoute element={<PasswordSignupPage />} />}
              />
              <Route
                path="/otp-verification"
                element={<ProtectedRoute element={<OTPVerificationPage />} />}
              />
              <Route
                path="/service-selection"
                element={<ProtectedRoute element={<ServiceSelectionPage />} />}
              />
              <Route
                path="/company-registration"
                element={<ProtectedRoute element={<CompanyFormPage />} />}
              />
              <Route
                path="/individual-registration"
                element={<ProtectedRoute element={<Individual />} />}
              />
              <Route
                path="/review"
                element={<ProtectedRoute element={<NotificationPage />} />}
              />
              <Route
                path="/dashboard"
                element={<ProtectedRoute element={<Dashboard />} />}
              />
              <Route
                path="/package-type"
                element={<ProtectedRoute element={<PackageType />} />}
              />
              <Route
                path="/company/package-creation"
                element={<ProtectedRoute element={<CreatePackagePage />} />}
              />
              <Route
                path="/packagedetails"
                element={<ProtectedRoute element={<PackageDetails />} />}
              />
              <Route
                path="/packages"
                element={<ProtectedRoute element={<PackageManagement />} />}
              />
              <Route
                path="/individual/package-creation"
                element={<ProtectedRoute element={<IndTransportForm />} />}
              />
              <Route
                path="/edit-package"
                element={
                  <ProtectedRoute element={<EditPackagePage isEditing />} />
                }
              />{" "}
              {/* Add the edit-package route */}
              <Route
                path="/company/continue-existing-package-creation"
                element={
                  <ProtectedRoute element={<ContinueCreatedCompanyForms />} />
                }
              />
              <Route
                path="/profile"
                element={<ProtectedRoute element={<Profile />} />}
              />
              <Route
                path="/booking"
                element={<ProtectedRoute element={<Bookings />} />}
              />
              <Route
                path="/bookingdetails"
                element={<ProtectedRoute element={<BookingDetails />} />}
              />
              <Route
                path="/wallet"
                element={<ProtectedRoute element={<Wallet />} />}
              />
              <Route
                path="/all-payments"
                element={<ProtectedRoute element={<AllPayments />} />}
              />
              <Route
                path="/package/upload-evisa"
                element={<ProtectedRoute element={<UploadEvisa />} />}
              />
              <Route
                path="/package/airline-tickets"
                element={<ProtectedRoute element={<AirlineTickets />} />}
              />
              <Route
                path="/package/transport-arrangement"
                element={<ProtectedRoute element={<TransportArrangement />} />}
              />
              <Route
                path="/package/hotel-arrangement"
                element={<ProtectedRoute element={<HotelArrangement />} />}
              />
              <Route
                path="/reviews-ratings"
                element={<ProtectedRoute element={<Reviews />} />}
              />
              <Route
                path="/withdrawhistory"
                element={<ProtectedRoute element={<WithdrawHistory />} />}
              />{" "}
              <Route
                path="/accountstatementhistory"
                element={
                  <ProtectedRoute element={<AccountStatementHistory />} />
                }
              />
              <Route
                path="/faq"
                element={<ProtectedRoute element={<FQA />} />}
              />
              <Route
                path="/privacy-policy"
                element={<ProtectedRoute element={<PrivacyPolicy />} />}
              />{" "}
              <Route
                path="/terms-of-services"
                element={<ProtectedRoute element={<TermsServices />} />}
              />
              <Route
                path="/documentation"
                element={<ProtectedRoute element={<Documentation />} />}
              />

              <Route
                path="/complaints"
                element={<ProtectedRoute element={<Complaints />} />}
              />
            </Routes>
            <ScrollToTopButton /> {/* Add the ScrollToTopButton component */}
            <ToastContainer
              position="top-right"
              hideProgressBar={true}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
            />
          </Router>
        </NavigationContext.Provider>
      </BookingProvider>
    </UserProvider>
  );
};

export default App;
